<template>
	<div id="app">
		<AppSidebar />
		<transition name="fade" mode="out-in">
			<router-view />
		</transition>
	</div>
</template>

<script>
	import AppSidebar from "@/components/AppSidebar.vue";

	export default {
		name: "App",
		components: {
			AppSidebar,
		},
	};
</script>

<style lang="scss">
	html,
	body {
		scroll-snap-type: y mandatory;
		scroll-behavior: smooth;
	}

	#app {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: $black;
		background: $primary;
		min-height: 100vh;
	}

	.fade-enter-active,
	.fade-leave-active {
		//		transition: transform 0.5s;
		transition: opacity 0.3s;
		//		transition: 0.75s ease-in-out;
	}

	.fade-enter,
	.fade-leave-to {
		//		left: -25vw;
		//		transform: translateX(100%);
		opacity: 0;
	}

	.fade-enter-to,
	.fade-leave {
		//	transform: translateX(0);
		opacity: 1;
	}
</style>
