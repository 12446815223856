<template>
	<div class="sidebar is-column is-centered-in-middle">
		<div class="logo">BOLAGSKOMPASSEN</div>
		<!--
		<div class="is-flex is-column">
			<router-link to="/" exact class="class">Start</router-link>
			<router-link to="/analyser" class="class">Analyser</router-link>
			<router-link to="/programvara" class="class">Programvara</router-link>
			<router-link to="/om-oss" class="class">Om oss</router-link>
		</div>
		<router-link to="/kunskap" class="class">Kunskapsbank</router-link>
-->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isActive: true,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.sidebar {
		display: flex;
		background: $dark;
		height: 100vh;
		width: 20vw;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
		text-align: left;
	}

	.class {
		padding: 1rem 0;
		border-left: 4px solid $dark;
		padding-left: 2rem;
		margin-left: 1rem;

		&:hover {
			background-color: $primary;
			border-color: $contrast;
		}
	}

	.logo {
		//		padding: 2rem 0;
		border-left: 4px solid $dark;
		//		padding-left: 3rem;
		color: $contrast;
	}

	a {
		color: $light;
		text-decoration: none;
		transition: 0.5s;

		&.router-link-active {
			background: $dark;
			color: $white;
			border-color: $secondary;
			padding: 0.75rem 0 0.75rem 2rem;
		}

		&.router-link-exact-active {
			background: $primary;
			color: $white;
			border-color: $secondary;
			padding: 2rem 0 2rem 2rem;
			margin-left: 1rem;
		}
		&:hover {
			color: $contrast;
		}
	}

@media screen and (max-width: 1400px) {
  .sidebar {
    display: none;
  }
}

</style>
