import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/styles/reset.css";
import "@/styles/globalcode.scss";
import "@/styles/projectcode.scss";
import vueScrollto from "vue-scrollto";

Vue.config.productionTip = false;
Vue.use(vueScrollto);

new Vue({
	router,
	render: (h) => h(App),
}).$mount("#app");
